<template>
    <Header />
    <h1>Add Script</h1>
    <form action="" class="script">
        <input class="scriptbox" type="text" name="script" placeholder="Enter Script" v-model="script.script" /><br>
        <button type="button" v-on:click="updateScript">Update Idea</button>
    </form>
</template>

<script>
import Header from './Header.vue';
import axios from 'axios';
export default {
    name: "Add-scripts",
    components: {
        Header
    },
    data() {
        return {
            script: {
                script: ''
            }
        }
    },

    methods: {
        async updateScript() {
            console.warn(this.script)
            const result = await axios.put("https://futuretracker-83b53-default-rtdb.asia-southeast1.firebasedatabase.app/script/" + this.$route.params.id + ".json", {

                script: this.script.script
            });
            if (result.status == 200) {
                this.$router.push({ name: 'viewscript' })
            }
        }
    },

    async mounted() {
        let user = localStorage.getItem('user-info');
        if (!user) {
            this.$router.push({ name: 'SignUp' })
        }
        const result = await axios.get('https://futuretracker-83b53-default-rtdb.asia-southeast1.firebasedatabase.app/script/' + this.$route.params.id + ".json")
        //console.warn(this.$route.params.id)
        console.warn(result.data)
        this.script = result.data
    }
};

</script>

<style>
.script {
    widows: 200px;
    height: 200px;
}

.scriptbox {
    height: 10rem;
    width: 50rem;
}

button {
    width: 100px;
    height: 40px;
    border: 1px solid skyblue;
    background: skyblue;
    color: #fff;
    cursor: pointer;
    margin-top: 1rem;
}
</style>