<template>

    <Header />
    <pie-chart :data="[['totalbids', bids], ['totalbids', bids]]"></pie-chart>
    <h1>Today's Target: 10</h1>
</template>

<script>
import Header from './Header.vue'
import axios from 'axios';
export default {
    name: "Home-Page",
    data() {
        return {
            name: '',
            details: [],
            bids: 0
        }
    },
    methods: {
        async deleteDetails(id) {
            let result = await axios.delete("https://futuretracker-83b53-default-rtdb.asia-southeast1.firebasedatabase.app/details.json /" + id);
            console.warn(result)
            if (result.status == 200) {
                this.loadData()
            }
        },
        async loadData() {
            let user = localStorage.getItem('user-info');
            this.name = JSON.parse(user).name;
            if (!user) {
                this.$router.push({ name: 'SignUp' })
            }
            let result = await axios.get("https://futuretracker-83b53-default-rtdb.asia-southeast1.firebasedatabase.app/details.json");
            console.warn(result)
            for (let key in result.data) {
                this.details.push({ ...result.data[key], id: key })
            }
            this.bids = this.details.length
        },
        async loadData1() {
            let user = localStorage.getItem('user-info');
            this.name = JSON.parse(user).name;
            if (!user) {
                this.$router.push({ name: 'SignUp' })
            }
            let result = await axios.get("https://futuretracker-83b53-default-rtdb.asia-southeast1.firebasedatabase.app/submitidea.json");
            console.warn(result)
            this.submitidea = result.data;
        }

    },
    components: {
        Header
    },
    async mounted() {
        this.loadData();
    }
};

</script>


<style>
td {
    width: 150px;
    height: 40px;
}

thead {
    background: #01539d;
    color: white;
}

tr {
    background: #eea47f;
    color: black;
}

table.tabledata {
    width: 98%;
    margin-left: 14px;
}

button {
    margin-left: 6px;
    border-radius: 5px;
}

a {
    text-decoration: none;
    color: black;
}

button.update {
    background: yellow;
    border: 1px solid white;
    height: 25px;
}

button.delete {
    background: red;
    border: 1px solid white;
    height: 25px;
}
</style>