<template>
    <Header />
    <h1>Add Details</h1>
    <form action="" class="add">
        <select name="username" v-model="details.username">
            <option disabled value="">Please Select Username</option>
            <option value="Nehal">Nehal</option>
            <option value="Sujay">Sujay</option>
            <option value="Swapnil">Swapnil</option>
            <option value="Abhijeet">Abhijeet</option>
            <option value="Sanket">Sanket</option>
            <option value="Nandkishor">Nandkishor</option>
            <span style="padding-left:5%">Your Choice is: {{ selected }}</span>
        </select>
        <select name="category" v-model="details.category">
            <option disabled value="">Please Select Category</option>
            <option value="Web">Web</option>
            <option value="Mobile">Mobile</option>
            <span style="padding-left:5%">Your Choice is: {{ selected }}</span>
        </select>
        <input type="text" name="upworkid" placeholder="Enter Upwork job link" v-model="details.upworkid" />
        <select name="projecttype" v-model="details.projecttype">
            <option disabled value="">Please Select Project Type</option>
            <option value="Fixed">Fixed</option>
            <option value="Hourly">Hourly</option>
            <span style="padding-left:5%">Your Choice is: {{ selected }}</span>
        </select>
        <input type="text" name="rateapplied" placeholder="Enter rate for applying" v-model="details.rateapplied" />
        <select name="projectduration" v-model="details.projectduration">
            <option disabled value="">Please Select Project Duration</option>
            <option value="Less than 1 month">Less than 1 month</option>
            <option value="1-3 months">1-3 months</option>
            <option value="3-6 months">3-6 months</option>
            <span style="padding-left:5%">Your Choice is: {{ selected }}</span>
        </select>
        <select name="complexitylevel" v-model="details.complexitylevel">
            <option disabled value="">Please Select Project Complexity</option>
            <option value="Easy">Easy</option>
            <option value="Medium">Medium</option>
            <option value="Hard">Hard</option>
            <span style="padding-left:5%">Your Choice is: {{ selected }}</span>
        </select>
        <select name="connectsused" v-model="details.connectsused">
            <option disabled value="">Connects used for bid</option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
            <option value="6">6</option>
            <span style="padding-left:5%">Your Choice is: {{ selected }}</span>
        </select>
        <select name="boostbid" v-model="details.boostbid">
            <option disabled value="">Have you Boost Bid</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
            <span style="padding-left:5%">Your Choice is: {{ selected }}</span>
        </select>
        <select name="totalproposal" v-model="details.totalproposal">
            <option disabled value="">Total proposal send</option>
            <option value="Less than 5">Less than 5</option>
            <option value="5 to 10">5 to 10</option>
            <option value="10 to 20">10 to 20</option>
            <option value="20 to 50">20 to 50</option>
            <option value="50 +">50 +</option>
            <span style="padding-left:5%">Your Choice is: {{ selected }}</span>
        </select><br>
        <button type="button" v-on:click="addDetails">Add Details</button>
    </form>
</template>

<script>
import Header from './Header.vue';
import axios from 'axios';
export default {
    name: "Add-details",
    components: {
        Header
    },
    data() {
        return {
            details: {
                username: '',
                category: '',
                upworkid: '',
                projecttype: '',
                rateapplied: '',
                projectduration: '',
                complexitylevel: '',
                connectsused: '',
                boostbid: '',
                totalproposal: ''
            }
        }
    },


    methods: {
        async addDetails() {
            console.warn(this.details)
            const result = await axios.post("https://futuretracker-83b53-default-rtdb.asia-southeast1.firebasedatabase.app/details.json", {
                id: this.details.object,
                username: this.details.username,
                category: this.details.category,
                upworkid: this.details.upworkid,
                projecttype: this.details.projecttype,
                rateapplied: this.details.rateapplied,
                projectduration: this.details.projectduration,
                complexitylevel: this.details.complexitylevel,
                connectsused: this.details.connectsused,
                boostbid: this.details.boostbid,
                totalproposal: this.details.totalproposal
            });
            if (result.status == 200) {
                this.$router.push({ name: 'Home' })
            }
            console.warn("result", result)
        }
    },

    mounted() {
        let user = localStorage.getItem('user-info');
        if (!user) {
            this.$router.push({ name: 'SignUp' })
        }
    }
};

</script>

<style>
select {
    height: 3rem;
    border-radius: 5px;
    margin: 2rem;
    border: 1px solid skyblue;
}
</style>