<template>
    <div class="nav">
        <router-link class="navclass" to="/dashboard">Dashboard</router-link>
        <router-link class="navclass" to="/">Home</router-link>
        <router-link class="navclass" to="/add">Add Details</router-link>
        <router-link class="navclass" to="/script">Scripts</router-link>
        <router-link class="navclass" to="/viewscript">View Script</router-link>
        <router-link class="navclass" to="/screenshot">Screenshots</router-link>
        <router-link class="navclass" to="/submitidea">Submit Idea</router-link>
        <router-link class="navclass" to="/viewidea">View Idea</router-link>
        <a class="navclass" v-on:click="logout" href="#">Logout</a>
    </div>
</template>

<script>
export default {
    name: 'Header-bar',
    methods: {
        logout() {
            localStorage.clear();
            this.$router.push({ name: 'Login' })
        }
    }
}
</script>

<style>
.nav {
    background: black;
    overflow: hidden;
}

.nav a {
    float: left;
    color: white;
    padding: 14px 16px;
    text-align: center;
    font-size: 17px;
    text-decoration: none;
    margin-right: 5px;
}

.nav a:hover {
    background: #ddd;
    color: #333;
}

.nav a.active {
    background: white;
    color: black;
}
</style>