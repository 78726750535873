<template>
    <Header />
    <h1>Submit Idea</h1>
    <form action="" class="idea">
        <input type="text" name="name" placeholder="Enter Idea name" v-model="submitidea.name" />
        <input type="text" name="category" placeholder="Enter Category" v-model="submitidea.category" />
        <input type="text" name="description" placeholder="Enter Description" v-model="submitidea.description" />
        <button type="button" class="reg" v-on:click="addidea">Submit Idea</button>
    </form>
</template>

<script>
import Header from './Header.vue';
import axios from 'axios';
export default {
    name: "Add-idea",
    components: {
        Header
    },
    data() {
        return {
            submitidea: {
                name: '',
                category: '',
                description: '',
            }
        }
    },
    methods: {
        async addidea() {
            console.warn(this.submitidea)
            const result = await axios.post("https://futuretracker-83b53-default-rtdb.asia-southeast1.firebasedatabase.app/submitidea.json", {
                name: this.submitidea.name,
                category: this.submitidea.category,
                description: this.submitidea.description
            });
            if (result.status == 200) {
                this.$router.push({ name: 'Viewidea' })
            }
            console.warn("result", result)
        }
    },
    mounted() {
        let user = localStorage.getItem('user-info');
        if (!user) {
            this.$router.push({ name: 'SignUp' })
        }
    }
};

</script>

<style>
.idea input {
    width: 300px;
    height: 40px;
    padding-left: 20px;
    display: block;
    margin-bottom: 30px;
    margin-right: auto;
    margin-left: auto;
    border: 1px solid skyblue;
}

.idea button {
    width: 320px;
    height: 40px;
    border: 1px solid skyblue;
    background: skyblue;
    color: #fff;
    cursor: pointer;
}
</style>