<template>
    <Header />
    <h1>Add Screenshot</h1>
    
</template>

<script>
import Header from './Header.vue';
export default{
    name:"Add-scripts",
    components:{
        Header
    },
    
    
    

    mounted()
    {
        let user=localStorage.getItem('user-info');
        if(!user)
        {
            this.$router.push({name:'SignUp'})
        }
    }
};

</script>