<template>
    <Header />
    <h1>Idea List</h1>
    <table border="1px solid black" class="tabledata">
        <thead>
            <td>Id</td>
            <td>Idea Name</td>
            <td>Idea Category</td>
            <td>Idea Description</td>
            <td>Action</td>
        </thead>
        <tr v-for="item in submitidea" :key="item.id">
            <td>{{ item.id }}</td>
            <td>{{ item.name }}</td>
            <td>{{ item.category }}</td>
            <td>{{ item.description }}</td>
            <td>
                <!-- <button class="update"><router-link :to="'/update/' + item.id">Update</router-link></button> -->
                <button class="delete" v-on:click="deleteDetails(item.id)">Delete</button>
            </td>
        </tr>
    </table>
</template>

<script>
import Header from './Header.vue'
import axios from 'axios';
export default {
    name: "idea-view",
    data() {
        return {
            name: '',
            submitidea: []
        }
    },
    methods: {
        async deleteDetails(key) {
            let result = await axios.delete("https://futuretracker-83b53-default-rtdb.asia-southeast1.firebasedatabase.app/submitidea/" + key + ".json");
            console.warn(result)
            location.reload()
        },
        async loadData1() {
            let user = localStorage.getItem('user-info');
            this.name = JSON.parse(user).name;
            if (!user) {
                this.$router.push({ name: 'SignUp' })
            }
            let result = await axios.get("https://futuretracker-83b53-default-rtdb.asia-southeast1.firebasedatabase.app/submitidea.json");
            console.warn(result)
            for (let key in result.data) {
                this.submitidea.push({ ...result.data[key], id: key })
            }
        }
    },
    components: {
        Header
    },
    async mounted() {
        this.loadData1();
    }
};

</script>


<style>
td {
    width: 150px;
    height: 40px;
}

thead {
    background: #01539d;
    color: white;
}

tr {
    background: #eea47f;
    color: black;
}

table.tabledata {
    width: 98%;
    margin-left: 14px;
}

button {
    margin-left: 6px;
    border-radius: 5px;
}

a {
    text-decoration: none;
    color: black;
}

button.update {
    background: yellow;
    border: 1px solid white;
    height: 25px;
}

button.delete {
    background: red;
    border: 1px solid white;
    height: 25px;
}
</style>