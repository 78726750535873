<template>
    <Header />
    <h1>Add Script</h1>
    <form action="" class="script">
        <textarea class="scriptbox" type="text" name="script" placeholder="Enter Script" v-model="script.script" /><br>
        <button type="button" v-on:click="addScript">Add Details</button>
    </form>
</template>

<script>
import Header from './Header.vue';
import axios from 'axios';
export default {
    name: "Add-scripts",
    components: {
        Header
    },
    data() {
        return {
            script: {
                script: ''
            }
        }
    },

    methods: {
        async addScript() {
            console.warn(this.script)
            const result = await axios.post("https://futuretracker-83b53-default-rtdb.asia-southeast1.firebasedatabase.app/script.json", {

                script: this.script.script
            });
            if (result.status == 200) {
                this.$router.push({ name: 'Viewscript' })
            }
            console.warn("result", result)
        }
    },

    mounted() {
        let user = localStorage.getItem('user-info');
        if (!user) {
            this.$router.push({ name: 'SignUp' })
        }
    }
};

</script>

<style>
button {
    width: 100px;
    height: 40px;
    border: 1px solid skyblue;
    background: skyblue;
    color: #fff;
    cursor: pointer;
    margin-top: 1rem;
}
</style>