import Home from './components/Home.vue';
import SignUp from './components/SignUp.vue';
import { createRouter, createWebHistory } from 'vue-router';
import Login from './components/Login.vue'
import Add from './components/Add.vue'
import Update from './components/Update.vue'
import Script from './components/Script.vue'
import Screenshot from './components/Screenshot.vue'
import Submitidea from './components/Submitidea.vue'
import Viewidea from './components/Viewidea.vue'
import Dashboard from './components/Dashboard.vue'
import Viewscript from './components/Viewscript.vue'
import Updatescript from './components/Updatescript.vue'

const routes=[
    {
        name:'Home',
        component:Home,
        path:'/'
    },
    {
        name:'SignUp',
        component:SignUp,
        path:'/sign-up'
    },
    {
        name:'Login',
        component:Login,
        path:'/login'
    },
    {
        name:'Add',
        component:Add,
        path:'/add'
    },
    {
        name:'Update',
        component:Update,
        path:'/update/:id'
    },
    {
        name:'Script',
        component:Script,
        path:'/script'
    },
    {
        name:'Screenshot',
        component:Screenshot,
        path:'/screenshot'
    },
    {
        name:'Submitidea',
        component:Submitidea,
        path:'/submitidea'
    },
    {
        name:'Viewidea',
        component:Viewidea,
        path:'/viewidea'
    },
    {
        name:'Dashboard',
        component:Dashboard,
        path:'/dashboard'
    },
    {
        name:'Viewscript',
        component:Viewscript,
        path:'/viewscript'
    },
    {
        name:'Updatescript',
        component:Updatescript,
        path:'/updatescript/:id'
    },
    
];

const router = createRouter({
    history:createWebHistory(),
    routes
})

export default router;