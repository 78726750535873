<template>


    <div class="login">
        <img class="logo" src="../assets/aaa.png" alt="">
        <h1>Login</h1>
        <input type="text" v-model="email" placeholder="Enter email">
        <input type="password" v-model="password" placeholder="Enter password">
        <button v-on:click="login">Login</button>

        <p>
            <router-link to="/sign-up">Sign Up</router-link>
        </p>
    </div>
</template>

<script>
import axios from 'axios'
export default {
    name: 'Login-Page',
    data() {
        return {
            email: '',
            password: ''
        }
    },
    methods: {
        async login() {
            let result = await axios.get(
                'https://futuretracker-83b53-default-rtdb.asia-southeast1.firebasedatabase.app/user.json?email=' + this.email + '&password=' + this.password
            )
            if (result.status == 200) {
                console.log(result.data)
                localStorage.setItem("user-info", JSON.stringify(result.data))
                this.$router.push({ name: 'Home' })
            }
            console.warn(result)
        }
    },
    mounted() {
        let user = localStorage.getItem('user-info');
        if (user) {
            this.$router.push({ name: 'Home' })
        }
    }
};
</script>

<style>
body {
    background-image: url('~@/assets/eco_start.jpg');
}
</style>

